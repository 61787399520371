import React from 'react';

import { DrivezyViewModel } from './models/drivezy-models';
import { PageLayout, PageHero } from '~/common/components';
import EligibilitySection from './components/eligibility-section';
import FaqsSection from './components/faqs-section';
import InclusionsSection from './components/inclusions-section';

import MainSection from './components/main-section';
import MechanicsSection from './components/mechanics-section';
import ShowroomSection from './components/showroom-section';

interface DrivezyShowroomProps {
  cdnBasePath: string;
  viewModel: DrivezyViewModel;
}

export const DrivezyShowroomContext = React.createContext('');

export const Drivezy: React.FC<DrivezyShowroomProps> = ({
  cdnBasePath,
  viewModel: {
    bannerImage,
    mainDescription,
    eligibility,
    mechanics,
    drivezyCategories,
    inclusions,
    faqSection,
  },
}: DrivezyShowroomProps) => {
  React.useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, []);

  return (
    <DrivezyShowroomContext.Provider value={cdnBasePath}>
      <PageLayout>
        <PageHero src={bannerImage} />
        <MainSection mainDescription={mainDescription} />
        <EligibilitySection eligibility={eligibility} />
        <MechanicsSection mechanics={mechanics} />
        <ShowroomSection categories={drivezyCategories} />
        <InclusionsSection inclusions={inclusions} />
        <FaqsSection faqSection={faqSection} />
      </PageLayout>
    </DrivezyShowroomContext.Provider>
  );
};
