import React, { useState } from 'react';
import DrivezyCard from './drivezy-card/drivezy-card';
import DrivezyCarousel from './drivezy-carousel/carousel';
import { DrivezyCategory } from '../../models/drivezy-models';
import { CategoryMenuDesktop, CategoryMenuMobile } from '../category-menu';
import styles from './index.module.scss';

interface ShowroomSectionProps {
  categories: DrivezyCategory[];
}

const ShowroomSection = ({ categories }: ShowroomSectionProps) => {
  const allModels = 'All Models';
  const getModelsForCategory = (category) => {
    return categories
      .filter((c) => {
        return category === allModels ? true : c.name === category;
      })
      .map((c) => c.drivezyModels.map((model) => ({ ...model })))
      .flat();
  };

  const [currentModels, setCurrentModels] = useState(
    getModelsForCategory(allModels)
  );

  return (
    <div className={styles.ShowroomSection__wrapper}>
      <div className={styles.ShowroomSection}>
        <h2
          className={styles.ShowroomSection__title}
          dangerouslySetInnerHTML={{ __html: 'Find a plan that works for you' }}
        />
        <CategoryMenuDesktop
          categories={categories}
          setCurrentModels={setCurrentModels}
        />
        <CategoryMenuMobile
          categories={categories}
          setCurrentModels={setCurrentModels}
        />
        <DrivezyCarousel>
          {currentModels.map((model, index) => {
            return <DrivezyCard key={index} model={model} />;
          })}
        </DrivezyCarousel>
      </div>
    </div>
  );
};

export default ShowroomSection;
