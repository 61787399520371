import React from 'react';
import { Button } from '@ui-elements';
import { DrivezyModel } from '../../../models/drivezy-models';
import styles from './drivezy-card.module.scss';
import cn from 'classnames';

export interface DrivezyCardProps {
  key: number;
  model: DrivezyModel;
}

const DrivezyCard: React.FC<DrivezyCardProps> = ({
  model,
}: DrivezyCardProps) => {
  return (
    <div className={styles.DrivezyCard__wrapper}>
      <img
        loading="lazy"
        className={styles.DrivezyCard__modelImg}
        src={model.modelImage}
        alt={model.name}
      />
      <div className={styles.DrivezyCard}>
        <div className={styles.DrivezyCard__modelImg}>
          <img
            loading="lazy"
            className={cn({
              [styles.DrivezyCard__modelBadge___extra]: !model.isHybrid,
            })}
            src={model.badge}
            alt={model.badge}
          />
        </div>
        <div className={styles.DrivezyCard__highlight}>{model.highlight}</div>
        <ul>
          {model.features.map((feature, index) => {
            return (
              <li key={index}>
                <div
                  id={`feature-${index}`}
                  className={styles.DrivezyCard__text}
                >
                  {feature.description}
                </div>
              </li>
            );
          })}
        </ul>
        <div className={styles.DrivezyCard__inclusions}>
          <div className={styles.DrivezyCard__inclusions___header}>
            Standard Inclusions
          </div>
          <ul>
            {model.inclusions.map((inclusion, index) => {
              return (
                <li key={index}>
                  <div
                    id={`inclusion-${index}`}
                    className={styles.DrivezyCard__text}
                  >
                    {inclusion.description}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <Button
          id={`${model.name.replaceAll(' ', '')}-drivezy-cta`}
          href={model.ctA_Link}
          type="primary"
          text={model.ctA_Text}
          block
        />
      </div>
    </div>
  );
};

export default DrivezyCard;
