import React from 'react';
import { Button } from '@ui-elements';
import { Inclusions } from '../../models/drivezy-models';
import styles from './index.module.scss';

interface InclusionsSectionProps {
  inclusions: Inclusions;
}

const InclusionsSection = ({
  inclusions: { title, description, ctAs, items: inclusions, image },
}: InclusionsSectionProps): JSX.Element => (
  <div className={styles.InclusionsSection}>
    <h2
      className={styles.InclusionsSection__title}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <div
      className={styles.InclusionsSection__description}
      dangerouslySetInnerHTML={{ __html: description }}
    />

    <div className={styles.InclusionsSection__keyFeatures___wrapper}>
      {inclusions.map((inclusion, index) => {
        return (
          <div
            className={styles.InclusionsSection__keyFeature___wrapper}
            key={index}
          >
            <img loading="lazy" src={inclusion.icon} />
            <div className={styles.InclusionsSection__keyFeature___title}>
              {inclusion.title}
            </div>
            <div className={styles.InclusionsSection__keyFeature___description}>
              {inclusion.description}
            </div>
          </div>
        );
      })}
    </div>
    <div className={styles.InclusionsSection__ctas___wrapper}>
      {ctAs.map((cta, index) => {
        return (
          <div
            className={styles.InclusionsSection__ctas___cta___wrapper}
            key={index}
          >
            <Button
              id={`inclusion-cta-${index}`}
              href={`${cta.link}`}
              type="primary"
              text={cta.text}
              block
              buttonWidth="full-width"
            />
          </div>
        );
      })}
    </div>
    <div className={styles.InclusionsSection__image}>
      <img loading="lazy" src={image} />
    </div>
  </div>
);

export default InclusionsSection;
