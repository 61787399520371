import React, { useContext } from 'react';
import { Button } from '@ui-elements';
import { Mechanics } from '../../models/drivezy-models';
import { DrivezyShowroomContext } from '../..';
import styles from './index.module.scss';

interface MechanicsSectionProps {
  mechanics: Mechanics;
}

const MechanicsSection = ({
  mechanics: { title, description, ctAs },
}: MechanicsSectionProps): JSX.Element => {
  const cdnBasePath = useContext(DrivezyShowroomContext);
  return (
    <div className={styles.MechanicsSection}>
      <h2
        className={styles.MechanicsSection__title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div>
        <div className={styles.MechanicsSection__steps}>
          <div className={styles.MechanicsSection__step}>
            <div className={styles.MechanicsSection__step___number}>
              <img
                loading="lazy"
                className={styles.MechanicsSection__step___number___image}
                src="/dist/assets/images/1.png"
              />
            </div>
            <div className={styles.MechanicsSection__step___text}>Apply</div>
          </div>
          <div className={styles.MechanicsSection__step___line} />
          <div className={styles.MechanicsSection__step}>
            <div className={styles.MechanicsSection__step___number}>
              <img
                loading="lazy"
                className={styles.MechanicsSection__step___number___image}
                src="/dist/assets/images/2.png"
              />
            </div>
            <div className={styles.MechanicsSection__step___text}>
              Subscribe
            </div>
          </div>
          <div className={styles.MechanicsSection__step___line} />
          <div className={styles.MechanicsSection__step}>
            <div className={styles.MechanicsSection__step___number}>
              <img
                loading="lazy"
                className={styles.MechanicsSection__step___number___image}
                src="/dist/assets/images/3.png"
              />
            </div>
            <div className={styles.MechanicsSection__step___text}>
              Take Delivery
            </div>
          </div>
          <div className={styles.MechanicsSection__step___line} />
          <div className={styles.MechanicsSection__step}>
            <div className={styles.MechanicsSection__step___number}>
              <img
                loading="lazy"
                className={styles.MechanicsSection__step___number___image}
                src="/dist/assets/images/4.png"
              />
            </div>
            <div className={styles.MechanicsSection__step___text}>
              Pay as you drive
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.MechanicsSection__description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className={styles.MechanicsSection__ctas___wrapper}>
        {ctAs.map((cta, index) => {
          return (
            <div
              className={styles.MechanicsSection__ctas___cta___wrapper}
              key={index}
            >
              <Button
                id={`mechanics-cta-${index}`}
                href={`${cta.link}`}
                type="secondary"
                block
                buttonWidth="full-width"
              >
                <span dangerouslySetInnerHTML={{ __html: cta.text }} />
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MechanicsSection;
