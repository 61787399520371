import React from 'react';
import { Button } from '@ui-elements';
import { Eligibility } from '../../models/drivezy-models';
import styles from './index.module.scss';
interface EligibilitySectionProps {
  eligibility: Eligibility;
}

const EligibilitySection = (props: EligibilitySectionProps): JSX.Element => {
  const { image, content, ctAs: ctas } = props.eligibility;

  return (
    <div>
      <div className={styles.EligibilitySection__main}>
        <div className={styles.EligibilitySection__main__left} id="left-div">
          <img loading="lazy" className="full" src={image} />
        </div>
        <div className={styles.EligibilitySection__main__right} id="right-div">
          <div className={styles.EligibilitySection__main__right__container}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div className={styles.EligibilitySection__ctas___wrapper}>
              {(ctas || []).map((cta, index) => {
                return (
                  <div
                    className={styles.EligibilitySection__ctas___cta___wrapper}
                    key={index}
                  >
                    <Button
                      className={styles.EligibilitySection__ctas___contrast}
                      id={`eligibility-cta-${index}`}
                      href={`${cta.link}`}
                      type={index === 0 ? 'primary' : 'secondary'}
                      text={cta.text}
                      block
                      buttonWidth="full-width"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EligibilitySection;
