import React, { useContext, useEffect, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import './carousel-styles.scss';
import { DrivezyShowroomContext } from '../../../';

const DrivezyCarousel = ({ children }) => {
  const cdnBasePath = useContext(DrivezyShowroomContext);
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1300 },
      items: 4,
    },
    largeTablet: {
      breakpoint: { max: 1299, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 799, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <>
        {currentSlide > 0 && (
          <div
            className="react-drivezy-multiple-carousel__arrow react-drivezy-multiple-carousel__arrow--left"
            onClick={() => {
              previous();
            }}
          >
            <img
              loading="lazy"
              className="react-drivezy-multiple-carousel__arrow--back"
              src="/dist/assets/images/arrow-next.png"
            />
          </div>
        )}
        {currentSlide < totalItems - slidesToShow && (
          <div
            className="react-drivezy-multiple-carousel__arrow react-drivezy-multiple-carousel__arrow--right"
            onClick={() => {
              next();
            }}
          >
            <img loading="lazy" src="/dist/assets/images/arrow-next.png" />
          </div>
        )}
      </>
    );
  };

  const carouselRef = useRef(null);

  useEffect(() => {
    carouselRef.current.goToSlide(0);
  }, [children]);

  return (
    <Carousel
      ref={carouselRef}
      responsive={responsive}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
      renderButtonGroupOutside
    >
      {children}
    </Carousel>
  );
};

export default DrivezyCarousel;
