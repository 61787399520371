import React, { useState } from 'react';
import cn from 'classnames';
import { SvgIcon } from '@ui-elements';
import { AccordionItem } from '~/pages/drivezy/models/drivezy-models';
import styles from './index.module.scss';

interface AccordionProps {
  cdnBasePath?: string;
  title: string;
  items: AccordionItem[];
}

export const Accordion: React.FC<AccordionProps> = ({ title, items: item }) => {
  return (
    <div className={styles.Accordion}>
      <h2
        className={styles.Accordion__title}
        dangerouslySetInnerHTML={{ __html: `${title}` }}
      />
      {item &&
        item.map((question, i) => {
          return <AccItem faq={question} key={i} index={i} />;
        })}
    </div>
  );
};

interface AccItemProps {
  cdnBasePath?: string;
  faq: AccordionItem;
  index: number;
}

const AccItem = ({ faq, index }: AccItemProps): JSX.Element => {
  const [collapse, setCollapse] = useState(true);

  const iconClass = cn(styles.Accordion__qa__iconWrapper___icon, {
    [styles.Accordion__qa__iconWrapper___down]: collapse,
    [styles.Accordion__qa__iconWrapper___up]: !collapse,
  });
  const targetZoneClass = cn(styles.Accordion__qa___button, {
    collapsed: collapse,
  });

  const answerClass = cn(styles.Accordion__qa__answer, {
    [styles.Accordion__qa__answer___collapsed]: collapse,
  });

  return (
    <div className={styles.Accordion__qa} key={index}>
      <a onClick={() => setCollapse(!collapse)} className={targetZoneClass}>
        <div
          id={`accordion_question_${index}`}
          className={styles.Accordion__qa__question}
          dangerouslySetInnerHTML={{ __html: faq.question }}
        />
        {/* <img
          loading="lazy"
          className={iconClass}
          src="dist/assets/svg-inline/arrow-next.svg"
        /> */}

        <div className={iconClass}>
          <SvgIcon type="chevronRight" size="18px" />
        </div>
      </a>

      <div
        id={`accordion_answer_${index}`}
        className={answerClass}
        dangerouslySetInnerHTML={{ __html: faq.answer }}
      />
    </div>
  );
};
