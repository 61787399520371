import React, { useContext } from 'react';
import { Button } from '@ui-elements';
import { FaqSection } from '../../models/drivezy-models';
import { Accordion } from '~/pages/drivezy/components/accordion';
import { DrivezyShowroomContext } from '../..';
import styles from './index.module.scss';

interface FaqsSectionProps {
  faqSection: FaqSection;
}

const FaqsSection = ({
  faqSection: { faqs, ctAs },
}: FaqsSectionProps): JSX.Element => {
  const cdnBasePath = useContext(DrivezyShowroomContext);
  return (
    <div className={styles.FaqsSection__wrapper}>
      <Accordion
        cdnBasePath={cdnBasePath}
        title="Frequently asked questions"
        items={faqs}
      />
      <div className={styles.FaqsSection__ctas___wrapper}>
        {ctAs.map((cta, index) => {
          return (
            <div
              className={styles.FaqsSection__ctas___cta___wrapper}
              key={index}
            >
              <Button
                id={`faqs-cta-${index}`}
                href={`${cta.link}`}
                type="primary"
                text={cta.text}
                block
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FaqsSection;
