import React, { useContext, useState } from 'react';
import { DrivezyCategory, DrivezyModel } from '../../models/drivezy-models';
import cn from 'classnames';
import styles from './index.module.scss';
import { SvgIcon } from '@ui-elements';
import { DrivezyShowroomContext } from '../..';

export interface CategoryMenuProps {
  categories: DrivezyCategory[];
  setCurrentModels: React.Dispatch<React.SetStateAction<DrivezyModel[]>>;
}

export const CategoryMenuDesktop: React.FC<CategoryMenuProps> = ({
  categories,
  setCurrentModels,
}: CategoryMenuProps) => {
  const allModels = 'All Models';
  const getModelsForCategory = (category) => {
    return categories
      .filter((c) => {
        return category === allModels ? true : c.name === category;
      })
      .map((c) => c.drivezyModels.map((model) => ({ ...model })))
      .flat();
  };
  const [currentCategory, setCurrentCategory] = useState(allModels);

  const allCategories = [allModels, ...categories.map((c) => c.name)];

  const deskTopStyle = cn(
    styles.CategoryMenu__categoryTabWrapper,
    styles.CategoryMenu__categoryTabWrapper__desktop
  );

  return (
    <div className={deskTopStyle}>
      {allCategories.map((category, index) => {
        const selected = cn(styles.CategoryMenu__categoryTabs, {
          [styles.CategoryMenu__categoryTabs___selected]:
            category === currentCategory,
        });

        const highlightSelected = cn(
          styles.CategoryMenu__categoryTabWrapper___item,
          {
            [styles.CategoryMenu__categoryTabWrapper___item___selected]:
              category === currentCategory,
          }
        );

        const itemWidth = index === 0 ? { width: '200px' } : { width: '100%' };

        return (
          <div key={index} className={highlightSelected}>
            <div
              id={`category-tab-${index}`}
              style={itemWidth}
              className={selected}
              onClick={() => {
                setCurrentModels(getModelsForCategory(category));
                setCurrentCategory(category);
              }}
            >
              {category}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const CategoryMenuMobile: React.FC<CategoryMenuProps> = ({
  categories,
  setCurrentModels,
}: CategoryMenuProps) => {
  const cdnBasePath = useContext(DrivezyShowroomContext);
  const allModels = 'All Models';
  const getModelsForCategory = (category) => {
    return categories
      .filter((c) => {
        return category === allModels ? true : c.name === category;
      })
      .map((c) => c.drivezyModels.map((model) => ({ ...model })))
      .flat();
  };
  const [currentCategory, setCurrentCategory] = useState(allModels);
  const allCategories = [allModels, ...categories.map((c) => c.name)];
  const mobileStyle = cn(
    styles.CategoryMenu__categoryTabWrapper,
    styles.CategoryMenu__categoryTabWrapper__mobile,
    styles.CategoryMenu__categoryTabWrapper__ul
  );
  const [collapse, setCollapse] = useState(true);
  const iconClass = cn(styles.CategoryMenu__iconWrapper___icon, {
    [styles.CategoryMenu__iconWrapper___down]: collapse,
    [styles.CategoryMenu__iconWrapper___up]: !collapse,
  });

  return (
    <div className={styles.CategoryMenu__categoryTabWrapper__mobile}>
      <div
        className={cn(
          styles.CategoryMenu__current,
          styles.CategoryMenu__categoryTabs,
          styles.CategoryMenu__categoryTabs___selected
        )}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <div className={styles.CategoryMenu__currentText}>
          {currentCategory}
        </div>
        <SvgIcon type="chevronDown" size={1} color="#1637A0" />
      </div>
      {!collapse && (
        <ul className={mobileStyle}>
          {allCategories
            .filter((cat) => cat !== currentCategory)
            .map((category, index) => {
              const selected = cn(
                styles.CategoryMenu__categoryTabs,
                styles.CategoryMenu__categoryTabWrapper__li,
                {
                  [styles.CategoryMenu__categoryTabs___selected]:
                    category === currentCategory,
                }
              );
              return (
                <li
                  key={index}
                  id={`category-tab-${index}`}
                  className={selected}
                  onClick={() => {
                    setCurrentModels(getModelsForCategory(category));
                    setCurrentCategory(category);
                    setCollapse(!collapse);
                  }}
                >
                  {category}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};
