import React, { useContext } from 'react';
import { Button, SvgIcon } from '@ui-elements';
import { MainDescription } from '../../models/drivezy-models';
import { DrivezyShowroomContext } from '../..';
import styles from './index.module.scss';
import { FullWidthView } from '~/macros/range-and-charging/components';

interface MainSectionProps {
  mainDescription: MainDescription;
}

const MainSection = (props: MainSectionProps): JSX.Element => {
  const { keyFeatures, content, ctAs } = props.mainDescription;
  const cdnBasePath = useContext(DrivezyShowroomContext);

  return (
    <div className={styles.MainSection__wrapper}>
      <div className={styles.MainSection}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <div className={styles.MainSection__keyFeatures___wrapper}>
          {(keyFeatures || []).map((feature, index) => {
            return (
              <div
                className={styles.MainSection__keyFeature___wrapper}
                key={index}
              >
                <SvgIcon type="check" size="20px" color="#1637a0" />
                <div className={styles.MainSection__keyFeature___title}>
                  {feature.title}
                </div>
                <div className={styles.MainSection__keyFeature___description}>
                  {feature.description}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.MainSection__ctas___wrapper}>
          {(ctAs || []).map((cta, index) => {
            return (
              <div
                className={styles.MainSection__ctas___cta___wrapper}
                key={index}
              >
                <Button
                  id={`main-description-cta-${index}`}
                  href={`${cta.link}`}
                  type={index === 0 ? 'primary' : 'secondary'}
                  text={cta.text}
                  block
                  buttonWidth="full-width"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainSection;
